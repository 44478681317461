<template>
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">{{ service.titre }}</h3>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="card shadow rounded">
                  <img
                    :src="service.cover_image"
                    class="img-responsive"
                    style="height:400px"
                    alt="avatar.png"
                  />
                </div>
              </div>
              <div class="col-md-8">
                <div class="card shadow rounded">
                  <div class="card-header flex-wrap border-0 pt-6 pb-0">
                    <div class="card-title">
                      Desciption du service
                    </div>
                  </div>
                  <div class="card-body">
                    {{ service.description }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Card-->
        <div class="card shadow rounded">
          <div class="card-header">
            <h4 class="card-title" style="margin: 0;">
              Tous les demandes du service
            </h4>
          </div>
          <div class="card-body">
            <table class="table">
              <thead>
                <tr>
                  <th>Demandeur</th>
                  <th>Description</th>
                  <th>Etats</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(request, index) in requests" :key="index">
                  <td>{{ request.user.nom }} {{ request.user.prenoms }}</td>
                  <td>{{ request.description }}</td>
                  <td v-if="request.etat === 0">
                    <span class="badge badge-dark">{{ etats["0"] }}</span>
                  </td>
                  <td v-if="request.etat === 1">
                    <span class="badge badge-primary">{{ etats["1"] }}</span>
                  </td>
                  <td v-if="request.etat === 2">
                    <span class="badge badge-danger">{{ etats["2"] }}</span>
                  </td>
                  <td v-if="request.etat === 3">
                    <span class="badge badge-success">{{ etats["3"] }}</span>
                  </td>
                  <td
                    class="btn-group"
                    v-if="request.etat === 0 || request.etat === 1"
                  >
                    <button
                      class="btn btn-info btn-sm"
                      @click="tryRequest(request)"
                      v-if="request.etat === 0"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-danger btn-sm"
                      @click="rejectRequest(request)"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                    <button
                      class="btn btn-success btn-sm"
                      @click="acceptRequest(request)"
                      v-if="request.etat !== 0"
                    >
                      <i class="fas fa-check"></i>
                    </button>
                  </td>
                  <td v-else-if="request.etat === 2">
                    <button
                      class="btn btn-info btn-sm"
                      @click="infoRejectRequest(request)"
                    >
                      <i class="fas fa-eye"></i> Voir détails
                    </button>
                  </td>
                  <td v-else>
                    <span class="badge badge-light"
                      ><i class="fas fa-check-double text-success"></i> Demande
                      accepter</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "Show",
  data() {
    return {
      initialize: "service/" + this.$route.params.id,
      service: [],
      requests: [],
      etats: {
        0: "En Attente...",
        1: "En traitement",
        2: "Rejeter",
        3: "Accepter"
      }
    };
  },
  methods: {
    rejectRequest(request) {
      this.reject(`demande/${request.id}/reject`, "demande");
    },
    infoRejectRequest(request) {
      this.info(request.motif);
    },
    acceptRequest(request) {
      this.accept(`demande/${request.id}/accept`, "Demande");
    },
    tryRequest(request) {
      Swal.fire({
        title: "Confirmation!",
        text: "Voulez vous vraiment traiter cette demande ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Annuller",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        }
      }).then(isConfirm => {
        if (isConfirm.isConfirmed) {
          ApiService.put(`demande/${request.id}/trying`)
            .then(response => {
              Swal.fire({
                title: "Success",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
              this.reloadData();
            })
            .catch(error => {
              Swal.fire({
                title: "Erreur",
                text: error.response.data.message,
                icon: "error",
                showConfirmButton: false,
                timer: 3000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
            });
        } else {
          Swal.fire({
            title: "Erreur",
            text: "Votre demande de traitement n'a pas été éffectuer !",
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
            showClass: {
              popup: "animate__animated animate__fadeInDown"
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp"
            }
          });
        }
      });
    },
    reject(url, name) {
      Swal.fire({
        title: "Confirmation!",
        text: `Voulez vous vraiment rejeter cette ${name} ?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Motif",
        cancelButtonText: "Annuller",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        }
      }).then(isConfirm => {
        if (isConfirm.isConfirmed) {
          Swal.fire({
            input: "textarea",
            inputLabel: "Message",
            inputPlaceholder: "Type your message here...",
            inputAttributes: {
              "aria-label": "Type your message here"
            },
            inputValidator: value => {
              if (!value) {
                return "Veillez saisir un motif svp!";
              }
            },
            showCancelButton: true
          }).then(isConfirm => {
            if (isConfirm.isDismissed) {
              Swal.fire({
                title: "Erreur",
                text: "Votre motif n'a été enregistrer !",
                icon: "error",
                showConfirmButton: false,
                timer: 3000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
            }
            if (isConfirm.isConfirmed) {
              let response = ApiService.put(url, { motif: isConfirm.value });
              if (response) {
                Swal.fire({
                  title: "Success",
                  text: "Votre motif a été enregistrer avec success !",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 3000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown"
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp"
                  }
                });
                this.reloadData();
              }
            }
          });
        }
      });
    },
    reloadData() {
      let vm = this;
      ApiService.get(this.initialize).then(function(response) {
        vm.service = response.data.service;
        vm.requests = response.data.demandes;
      });
    },
    info(data) {
      Swal.fire({
        title: "Motif du rejet",
        text: data,
        icon: "info",
        confirmButtonText: "Ok"
      });
    },
    accept(url, name) {
      Swal.fire({
        title: "Confirmation!",
        text: `Voulez vous vraiment accepter cette ${name} ?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accepter",
        cancelButtonText: "Annuller",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        }
      }).then(isConfirm => {
        if (isConfirm.isConfirmed) {
          let response = ApiService.get(url);
          if (response) {
            Swal.fire({
              title: `${name} accepter!`,
              text: `${name} à bien été accepter avec success`,
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              showClass: {
                popup: "animate__animated animate__fadeInDown"
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp"
              }
            });
            this.reloadData();
          }
        } else {
          Swal.fire({
            title: "Erreur",
            text: "l'action n'a pas été effectuer !",
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
            showClass: {
              popup: "animate__animated animate__fadeInDown"
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp"
            }
          });
        }
      });
    }
  },
  mounted() {
    this.reloadData();
  }
};
</script>

<style scoped></style>
